<template>
  <div class="offer">
    <span
      @click="emit_edit(index)"
      style="color: green; font-size: 12px; cursor: pointer; margin-right: 15px"
      v-if="offer_data.my_offer"
    >
      (edit)
    </span>
    {{ offer_data.mortgage_amount | formatPrice }}
    {{ offer_data.mortgage_position | mortgagePosition }} mortgage @
    {{ offer_data.interest_rate }}% interest <br />
    {{ offer_data.mortgage_term }} Months Term with {{ offer_data.lender_fee }}%
    Lender Fee for {{ offer_data.lender_fee + offer_data.interest_rate }} %
    Yield <br />
    {{
      offer_data.term_type
        ? offer_data.term_type === "Open - After Months"
          ? ` 
    with a term type of Open - After ${
      offer_data.number_of_months > 1
        ? `${offer_data.number_of_months} Months`
        : `${offer_data.number_of_months} Month`
    }`
          : `${offer_data.term_type}`
        : ""
    }}
    <br />
    <br />
    Submitted: {{ offer_data.createdAt | dateFormat }}
  </div>
</template>

<script>
import filters from "@/plugins/filters";
export default {
  props: ["offer_data", "index"],
  filters: {
    ...filters,
  },
  methods: {
    emit_edit(index) {
      this.$emit("edit_summary_offer_dialog_activate_mobile", index);
    },
  },
};
</script>

<style scoped lang="scss">
.offer {
  border-bottom: 0.5px solid grey;
  padding: 10px;
  font-size: 10px;
}
</style>
